(function (angular, app) {
    'use strict';
    function CartService() {}

    app.config(['SpCartServiceProvider', function (spCartServiceProvider) {
        spCartServiceProvider.initCart = ['cart', 'LocalStorage', function (cart, localStorageService) {
            cart.serverCartId = localStorageService.getItem('serverCartId');
            cart.lines = localStorageService.getItem('cart') || {};
            cart.outOfStockLines = [];
        }];

        spCartServiceProvider.getLocals = ['$injector', '$q', '$state', 'Config', 'User', function ($injector, $q, $state, Config, User) {
            return Config.initPromise.then(function() {
                return User.getData().catch(function() { /*do nothing*/ });
            }).then(function(userData) {
                var branchArea,
                    isInCheckoutPage = $state.current && $state.current.data && $state.current.data.name === 'checkout',
                    isInEditOrder = !!$injector.get('Orders').orderInEdit;

                // if there is no area after the config init the getBranchArea will throw an error
                // in this case the delivery items limit will be validated ones an area will be chosen
                try {
                    branchArea = Config.getBranchArea();
                } catch (e) {}

                return {
                    includeTaxInPrice: Config.retailer.includeTaxInPrice,
                    isRegularPriceWithTax: Config.retailer.isRegularPriceWithTax,
                    userId: User.session && User.session.userId || null,
                    loyaltyClubIds: userData && userData.loyaltyClubs && userData.loyaltyClubs.length && userData.loyaltyClubs.map(function (loyaltyClub) {
                        return loyaltyClub.loyaltyClubId;
                    }) || null,
                    apiOptions: {
                        loadingElement: isInCheckoutPage ? null : document.querySelector('html .sidenav footer .loading-wrapper') // TODO: move to provider and config with template module
                    },
                    languageId: Config.language.id,
                    withDeliveryProduct: isInCheckoutPage || isInEditOrder,
                    deliveryItemsLimit: branchArea && branchArea.deliveryItemsLimit
                };
            });
        }];
    }]).service('Cart', [
        '$rootScope', '$state', '$injector', '$q', '$filter', 'SpCartService', 'LocalStorage', 'Util', 'Dialog',
        'Bubble', 'User', 'DeliveryItemsLimitDialog', 'Config', 'DeliveryWithinDaysWarningDialog', '$timeout',
        'PRODUCT_TAG_TYPES', 'DataLayer', 'Config', 'SP_SERVICES', 'DELIVERY_TIMES_TYPES', 'CHARGE_SPECIALS_CALCULATION_TIME', 'Api',
        function($rootScope, $state, $injector, $q, $filter, spCartService, localStorageService, util, dialog,
                 bubble, userService, DeliveryItemsLimitDialog, config, DeliveryWithinDaysWarningDialog, $timeout,
                 PRODUCT_TAG_TYPES, DataLayer, Config, SP_SERVICES, DELIVERY_TIMES_TYPES, CHARGE_SPECIALS_CALCULATION_TIME, Api) {
            CartService.prototype = spCartService;
            var cart = new CartService(),
                Orders,
                _ageRestrictionDialog,
                _deliveryItemsLimitDialog,
                promiseChain = $q.resolve(),
                _isInCartMerge = false,
                ageValidationListener = $rootScope.$on('cart.update.complete', _handleAgeValidations),
                _productNameFilter = $filter('productName'),
                _translateFilter = $filter('translate'),
                _cartLineQuantityFilter = $filter('cartLineQuantity'),
                _isRetailerPremiumReplacementsEnabled = config.retailer.settings.enablePersonalReplacement;

            cart.irreSalesData = {
              lineTypes: [],
              expiredInfo: [],
              irrelevantSalesLines: [],
              skipDialogSpecial: false,
              outOfStockLines: [],
            };

            cart.close = closeCart;
            cart.checkUserCreditLimited = checkUserCreditLimited;
            cart.forceUpdate = forceUpdate;
            cart.checkIsLimitHeavyPackagePassed = checkIsLimitHeavyPackagePassed;
            cart.showDialogHeavyPackage = showDialogHeavyPackage;
            cart.checkHeavyLimitBeforeAddLine = checkHeavyLimitBeforeAddLine;
            cart.checkHeavyLimitBeforeAddLines = checkHeavyLimitBeforeAddLines;
            cart.checkHeavyLimitBeforeAddQuantity = checkHeavyLimitBeforeAddQuantity;
            cart.checkIsCaseProduct = checkIsCaseProduct;
            cart.sortCartByCategories = sortCartByCategories;
            cart.sortByTree = sortByTree;
            cart.filterCartLineRemoved = filterCartLineRemoved;
            cart.addDeliveryFeeLineIfNeeded = addDeliveryFeeLineIfNeeded;
            cart.isEligibleForReplacementSuggestions = isEligibleForReplacementSuggestions;
            cart.isSuggestionsActive = isSuggestionsActive;
            cart.setReplacements = setReplacements;
            cart.setDebugIsForStock = setDebugIsForStock;
            cart.checkIrrelevantSales = checkIrrelevantSales;
            cart.setIrreSalesData = setIrreSalesData;

            /**
              * Whether the cart element is closed or not
              * @type {boolean}
              */
            var closed = false;
            if (localStorageService.isExist('cartClosed')) {
                closed = localStorageService.getItem('cartClosed') === '1' ? true : closed;
            }
            _setCloseSidenav();

            /**
             * force update all cartlines
             * @public
             *
             * @returns {Promise}
             */
            function forceUpdate() {
                var deferred = $q.defer();

                //in order to save the current prices of the lines into the database
                $rootScope.forceUpdateCart = true;

                var cartLines = cart.getLines();
                angular.forEach(cartLines, function (line) {
                    cart.quantityChanged(line, true);
                });

                var listener = $rootScope.$on('cart.update.complete', function () {
                    listener(); //unregister from the listener
                    deferred.resolve();
                });

                return deferred.promise;
            }

            /**
              * Sets the closed flag by val and/or gets the current value
              * @param {boolean} [val]
              * @returns {boolean}
              * @public
              */
            function closeCart(val) {
                if (!angular.isUndefined(val)) {
                    var _prevClosed = closed;
                    closed = val;
                    localStorageService.setItem('cartClosed', val ? '1' : '0');
                    if (closed !== _prevClosed) {
                        $rootScope.$emit('cart.' + (closed ? 'closed' : 'shown'));
                    }

                    _setCloseSidenav();
                }

                return closed;
            }

            function _setCloseSidenav() {
                if (closed) {
                    angular.element(document.querySelector('html')).addClass('close-sidenav');
                } else {
                    angular.element(document.querySelector('html')).removeClass('close-sidenav');
                }
            }

            /**
             * Shows a bubble with current line details
             * @param {object} line
             * @private
             */
            function _showBubble(line) {
                var sidenav = document.querySelector('.sidenav');

                bubble.show({
                    controller: ['$scope', function ($scope) {
                        $scope.line = line;
                    }],
                    fixed: true,
                    position: {
                        bottom: 7,
                        left: sidenav.clientHeight ? 0 : 10
                    },
                    showClass: 'cart-line-bubble',
                    templateUrl: 'template/bubbles/cart-line-bubble/index.html',
                    nextTo: sidenav.clientHeight ? sidenav : document.querySelector('body > header'),
                    hideTime: 2000
                });
            }

            function _showLinesBubblesOnEvent(event, data) {
                if (!cart.close()) return;

                angular.forEach(data.lines, function(line) {
                    _showBubble(line);
                });
            }

            function _showQuantityLimitDialog(event, data) {
                util.showCommonDialog({
                    title: '{{\'Quantity Limitation\' | translate}}',
                    content: '{{(line.product.names | name).short}} {{\'is limited to \' | translate}}{{line.product.quantityLimit}} {{\'items only\' | translate}}',
                    controller: ['$scope', function($scope) {
                        $scope.line = data.line;
                    }],
                    bypass: true
                });
            }

            /**
             * handles all the validates pertaining to the age limit of every line added
             * @private
             */
            function _handleAgeValidations() {
                ageValidationListener();

                if (!userService.session.ageRestriction) {
                    angular.forEach(cart.lines, function (line) {
                        if (line.product && line.product.branch && line.product.branch.ageRestriction &&
                            (userService.session.ageRestriction || 0) < line.product.branch.ageRestriction) {
                            userService.session.ageRestriction = line.product.branch.ageRestriction;
                        }
                    });
                }

                $rootScope.$on('cart.lines.add', function (event, data) {
                    angular.forEach(data.lines, function (line) {
                        promiseChain = promiseChain.then(function () {
                            return _validateAge(line);
                        });
                    });
                });
            }

            /**
             * validates the age limit of the line
             * @param {object} line
             * @private
             */
            function _validateAge(line) {
                if (!line || !line.product || !line.product.branch || !line.product.branch.ageRestriction || line.product.branch.ageRestriction <= (userService.session && userService.session.ageRestriction || 0)) {
                    return $q.resolve(true);
                }

                if (_ageRestrictionDialog) {
                    return _ageRestrictionDialog.then(function() {
                        return _validateAge(line);
                    });
                }

                return _ageRestrictionDialog = dialog.show({
                    controller: 'AgeRestrictionCtrl',
                    templateUrl: 'template/dialogs/age-restriction/index.html',
                    styleClass: 'age-restriction-dialog',
                    bypass: true,
                    locals: {
                        ageLimit: line.product.branch.ageRestriction
                    },
                    disableClosing: true
                }).then(function(isValid) {
                    if (isValid) {
                        userService.session.ageRestriction = line.product.branch.ageRestriction;
                        userService.save();
                        $timeout(function() {
                            $rootScope.$emit('cart.update.ageRestriction.success');  
                        }, 1000);
                    } else {
                        cart.removeLine(line, true);
                        $rootScope.$emit('cart.update.ageRestriction.failed');
                    }
                    _ageRestrictionDialog = null;
                    return isValid;
                });
            }

            function _handleDeliveryItemsLimit(event, data) {
                if (_deliveryItemsLimitDialog) {
                    return;
                }

                return DeliveryItemsLimitDialog.show().finally(function() {
                    _deliveryItemsLimitDialog = null;
                });
            }

            /**
             * Alert popup if total exceed the Customer Credit
             * @public
             * @param {Boolean} includeTax
             * @param {Boolean} isOrderInEditMode
             * @param {String} goToState
             *
             * @returns {Promise<boolean>}
             */
            function checkUserCreditLimited(includeTax, goToState, isOrderInEditMode) {
                if (!userService.session || !userService.session.userId) {
                    return $q.resolve(false);
                }

                var cartTotal = !includeTax ? this.total.finalPriceForView :
                    this.total.finalPriceWithTax + this.total.serviceFee.finalPriceWithTax + this.total.deliveryCost.finalPriceWithTax;

                if(isOrderInEditMode) {
                    if(Config.retailer.settings.includeDeliveryFeeInCart === 'true') {
                        cartTotal = this.total.priceWithoutPromotionProductsForView;
                    } else {
                        cartTotal -= this.total.priceWithoutPromotionProductsForView;
                    }
                }

                return userService.getData(true).then(function(){
                    if ($rootScope.isCreditCustomer && $rootScope.creditCustomerRemainingSum < cartTotal) {
                        dialog.show({
                            templateUrl: 'template/dialogs/credit-customer-alert/index.html',
                            controller: ['$scope', function ($scope) {
                                $scope.remainingCredit = $rootScope.creditCustomerRemainingSum;
                                $scope.cartTotal = cartTotal;
                                $scope.removeSum = Math.abs($rootScope.creditCustomerRemainingSum - cartTotal);
                                $scope.cancel = function () {
                                    dialog.hide();
                                };
                            }]
                        }).then(function() {
                            !!goToState && $state.go(goToState);
                        });
                        return true;
                    }
                    return false;
                });
            }

            /**
             * Show delivery within days warning when adding non delivery within days products
             * @private
             *
             * @param {Object} event
             * @param {Object} data
             * @param {Object} data.value
             * @param {Object} data.oldValue
             *
             * @returns {Promise|void}
             */
            function _showDeliveryWithinDaysWarning(event, data) {
                // when the cart included a non delivery within days product before, do nothing
                if (data && data.oldValue && data.oldValue.none && !data.oldValue[PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS]) {
                    return;
                }

                return DeliveryWithinDaysWarningDialog.show();
            }

            function checkHeavyLimitBeforeAddQuantity (cartLineId, productId, quantity) {
                var tempCartLines = angular.copy( cart.lines);
                if (tempCartLines[cartLineId]) {
                    tempCartLines[cartLineId].quantity = quantity;
                } else {
                    tempCartLines[cartLineId] = { quantity: quantity };
                }
                var result = checkIsLimitHeavyPackagePassed(tempCartLines, productId, cartLineId);
                if (result && result.isLimitPassed) {
                    showDialogHeavyPackage(result.maxQuantity, result.limit);
                    return true;
                } else {
                    return false;
                }
            }

            function checkHeavyLimitBeforeAddLine (line) {
                var tempCartLines = angular.copy( cart.lines);
                var productId = line.product.productId;
                var cartLineId;
                if (!line.id) {
                    var isCaseProduct = checkIsCaseProduct(line);
                    if (isCaseProduct) {
                        cartLineId = line.product.id + '1';
                    } else {
                        cartLineId = line.product.id + '0';
                    }
                } else {
                    cartLineId = line.id;
                }
                tempCartLines[cartLineId] = line;
                if (!tempCartLines[cartLineId].quantity) {
                    tempCartLines[cartLineId].quantity = tempCartLines[cartLineId].product && tempCartLines[cartLineId].product.isWeighable ? tempCartLines[cartLineId].unitResolution : 1;
                }
                var result = checkIsLimitHeavyPackagePassed(tempCartLines, productId, cartLineId);
                if (result && result.isLimitPassed) {
                    showDialogHeavyPackage(result.maxQuantity, result.limit);
                    return true;
                } else {
                    return false;
                }
            }

            function checkHeavyLimitBeforeAddLines (newLines) {
                var tempCartLines = angular.copy(cart.lines);
                angular.forEach(newLines, function (line) {
                    var lineId;
                    if (line.id) {
                        lineId = line.id;
                    } else {
                        var isCaseProduct = checkIsCaseProduct(line);
                        if (isCaseProduct) {
                            lineId = line.product.id + '1';
                        } else {
                            lineId = line.product.id + '0';
                        }
                    }
                    if (!tempCartLines[lineId]) {
                        tempCartLines[lineId] = line;
                    } else {
                        tempCartLines[lineId].quantity += line.quantity;
                    }
                });
                var result = checkIsLimitHeavyPackagePassed(tempCartLines, null, null);
                if (result && result.isLimitPassed) {
                    showDialogHeavyPackage(result.maxQuantity, result.limit);
                    return true;
                } else {
                    return false;
                }
            }

            function checkIsLimitHeavyPackagePassed (cartLines, productId, mainProductId) {
                var body = {};
                var keys = Object.keys(cartLines);
                angular.forEach(keys, function (key) {
                    var isSingleCaseProduct = cartLines[key].product && cartLines[key].product.branch && cartLines[key].product.branch.case && cartLines[key].product.branch.case.price;
                    body[key] = {
                        id: cartLines[key].id,
                        quantity: calculateQuantity(cartLines[key]),
                        product: {
                            id: cartLines[key].product ? cartLines[key].product.productId : productId,
                            mainProductId: cartLines[key].product ? cartLines[key].product.id : mainProductId,
                            isSingleCase: isSingleCaseProduct ? isSingleCaseProduct && cartLines[key].isCase !== true : false,
                        },
                        isActive: cartLines[key].product && cartLines[key].product.branch ? cartLines[key].product.branch.isActive : true,
                    }
                });
                return isLimitPassedForHeavyPackages({ body: body, productId: productId, mainProductId: parseInt(mainProductId)});
            }

            function calculateQuantity (line) {
                var quantity = line.quantity;
                if (line.product) {
                    if (line.product && line.product.isWeighable) {
                        if (!!line.product.soldBy && line.product.soldBy === $rootScope.PRODUCT_DISPLAY.WEIGHT.name) {
                            quantity = line.quantity;
                        } else {
                            quantity = line.product.weight ? line.quantity * line.product.weight : line.quantity;
                        }
                    }
                }
                return quantity;
            }

            function showDialogHeavyPackage (maxQuantity, limit) {
                var languageContent = limit.languages[Config.language.id] ? limit.languages[Config.language.id] : limit.languages[0];
                dialog.show({
                    controller: 'LimitHeavyPackageCtrl',
                    templateUrl: 'template/dialogs/limit-heavy-package/index.html',
                    styleClass: 'limit-heavy-package-dialog',
                    bypass: true,
                    locals: {
                        maxQuantity: maxQuantity,
                        displayName: languageContent.displayName,
                        iconUrl: languageContent.iconResourceUrl,
                    }
                });

            }

            function isLimitPassedForHeavyPackages (data) {
                if (data.productId) {
                    var productContainsHeavyTag = checkIfProductContainsHeavyTag(data);
                    if (productContainsHeavyTag) {
                        return calculateIsLimitPassed(data);
                    } else {
                        return { isLimitPassed: false, maxQuantity: null };
                    }
                } else {
                    return calculateIsLimitPassed(data);
                }
            }

            function checkIfProductContainsHeavyTag (data) {
                var arrayOfProductTagProducts = Config.arrayOfProductTagProducts;
                var arrayOfRetailerProductTagProducts = Config.arrayOfRetailerProductTagProducts;

                if ((data.productId && checkIfItContainedInArray(arrayOfProductTagProducts, data.productId) ) ||
                (data.mainProductId && checkIfItContainedInArray(arrayOfRetailerProductTagProducts, data.mainProductId))) {
                    return true;
                } else return false;
            }

            function calculateIsLimitPassed (data) {
                var heavyPackageLimits = Config.retailerHeavyTagLimitations;
                var mapOfProductTagProducts = Config.mapOfProductTagProducts;
                var mapOfRetailerProductTagProducts = Config.mapOfRetailerProductTagProducts;
                var body = data.body;
                var i, j;
                for (i=0; i < heavyPackageLimits.length; i++) {
                    var count = 0;
                    var limit = heavyPackageLimits[i];
                    var bodyKeys = Object.keys(body);
                    for (j=0; j < bodyKeys.length; j++) {
                        var key = bodyKeys[j];
                        var line = body[key];
                        var countSingleProducts = !(limit.excludeSingleProducts && line.product.isSingleCase);
                        if (line.isActive && countSingleProducts) {
                            if ((mapOfProductTagProducts[limit.productTagId] && checkIfItContainedInArray(mapOfProductTagProducts[limit.productTagId],line.product.id))
                                || (mapOfRetailerProductTagProducts[limit.productTagId] && checkIfItContainedInArray(mapOfRetailerProductTagProducts[limit.productTagId],line.product.mainProductId))) {
                                count += line.quantity;
                            }
                        }
                    }
                    if (count > limit.maxQuantity) {
                        return { isLimitPassed: true, maxQuantity: limit.maxQuantity, limit: limit };
                    }
                }
                return { isLimitPassed: false, maxQuantity: null };
            }

            function checkIfItContainedInArray (array, id) {
                if (array && id) {
                    var stringId = id.toString();
                    var i;
                    for (i=0; i < array.length; i++) {
                        var item = array[i];
                        if (stringId.includes(item.toString())) return true;
                    }
                    return false;
                }
                return false;
            }

            function sortCartByCategories (items, sortedCartByCategories, data, secondLevelSort) {
                if (!sortedCartByCategories) {
                    sortedCartByCategories = [];
                }
                var filtered = [],
                remainingElementsToEnd = 0
                angular.forEach(items, function (item, key) {
                    if (item.type !== SP_SERVICES.CART_LINE_TYPES.DELIVERY) {
                        filtered.push(item);
                        item.$key = key;
                    }
                });

                if(secondLevelSort) {
                    filtered.sort(function (a, b) {
                        if (checkForCategoryLevel(a, 2) && checkForCategoryLevel(b, 2)) {
                            return b.product.family.categories[1].id - a.product.family.categories[1].id
                        }
                    });
                }
                var scrollToCategory = null
                var sortedObjByCategories = sortedCartByCategories;
                if(sortedCartByCategories && sortedCartByCategories.length < 1 || (data && data.lines && data.lines.length > 1)) {
                    if (filtered.length) {
                        sortedObjByCategories = filtered.reduce(function (acc, item) {
                            if (checkForCategoryLevel(item, 1) || item.isPseudo || item.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                                    var mainCategory = getProductMainCategory(item);
                                    item.mainCategoryNames = mainCategory.names;
                                    acc[mainCategory.id] = acc[mainCategory.id] || [];
                                    acc[mainCategory.id].push(item)
                                }
                                if(filtered.length === 1) {
                                    scrollToCategory = getArrayOrder(sortedObjByCategories, mainCategory.id)
                                }

                            return acc;
                        }, Object.create(null))
                    } else {
                        return []
                    }
                } else {
                    var product = {};
                    if(data && data.lines && data.lines.length) {
                        product = data.lines[0];
                    }
                    var sortAndNavigateData = sortAndNavigateToProduct(product, sortedObjByCategories, remainingElementsToEnd, scrollToCategory);

                }
                return {
                    sortedObjByCategories: sortedObjByCategories,
                    scrollToCategory: sortAndNavigateData ? sortAndNavigateData.scrollToCategory : scrollToCategory,
                    remainingElementsToEnd: sortAndNavigateData ? sortAndNavigateData.remainingElementsToEnd : remainingElementsToEnd};
            }

            function sortAndNavigateToProduct(product, sortedObjByCategories, remainingElementsToEnd, scrollToCategory) {
                // Sort by level 1
                if (sortedObjByCategories && checkForCategoryLevel(product, 1) || product.isPseudo || product.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                    var mainCategory = getProductMainCategory(product),
                        lastIndexOfAdded = -1,
                        addedProductSecondLevelLast = -1,
                        secondaryCategoryId = null,
                        existingProduct = false;

                    if(sortedObjByCategories && sortedObjByCategories[mainCategory.id]) {
                        existingProduct = sortedObjByCategories[mainCategory.id].some(function (item) {
                            return product.id === item.id;
                        });
                    }

                    product.mainCategoryNames = mainCategory.names;
                    sortedObjByCategories[mainCategory.id] = sortedObjByCategories[mainCategory.id] || [];

                    // Sort by level 2
                    if (!product.isPseudo && checkForCategoryLevel(product, 2)) {
                        secondaryCategoryId = product.product.family.categories[1].id;
                        lastIndexOfAdded = sortedObjByCategories[mainCategory.id].map(function (item) {
                            if(checkForCategoryLevel(item, 2)) {
                                return item.product.family.categories[1].id;
                            }
                        }).lastIndexOf(secondaryCategoryId)

                        if (lastIndexOfAdded < 0 && sortedObjByCategories[mainCategory.id].length) {
                            addedProductSecondLevelLast = sortedObjByCategories[mainCategory.id].findIndex(function (item) {
                                return secondaryCategoryId > item.product.family.categories[1].id
                            })
                        }
                    }

                    if(!existingProduct) {
                        if(lastIndexOfAdded > -1) {
                            sortedObjByCategories[mainCategory.id].splice(lastIndexOfAdded + 1, 0, product)
                            remainingElementsToEnd = sortedObjByCategories[mainCategory.id].slice(lastIndexOfAdded, (sortedObjByCategories[mainCategory.id].length - 2)).length;
                        } else if (addedProductSecondLevelLast > -1) {
                            if(addedProductSecondLevelLast < 1) {
                                sortedObjByCategories[mainCategory.id].unshift(product);
                                remainingElementsToEnd = sortedObjByCategories[mainCategory.id].length - 1;
                            } else {
                                sortedObjByCategories[mainCategory.id].splice(addedProductSecondLevelLast > -1 ? addedProductSecondLevelLast : lastIndexOfAdded, 0, product);
                                remainingElementsToEnd = sortedObjByCategories[mainCategory.id].slice(lastIndexOfAdded, (sortedObjByCategories[mainCategory.id].length - 1)).length;
                            }

                        } else {
                            sortedObjByCategories[mainCategory.id].push(product)
                        }
                        scrollToCategory = getArrayOrder(sortedObjByCategories, mainCategory.id)

                        return {
                            scrollToCategory: scrollToCategory,
                            remainingElementsToEnd: remainingElementsToEnd
                        }
                    }
                }
            }

            function getProductMainCategory(product) {
                var mainCategory = {};
                if(product.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                    mainCategory = {id: 'coupon'}
                } else {
                    mainCategory = product.isPseudo ? product.product && product.product.categories && product.product.categories[0] ? product.product.categories[0] : {id: 'unknown'} : product.product.family.categories[0]
                }
                return mainCategory;
            }

            function sortByTree(sortedObjByCategories) {
                sortedObjByCategories = sortedObjByCategories || {};
                var categories = (config && config.tree && config.tree.categories) ? config.tree.categories : [];
                var sortedByCategories = [];
                var sortedKeys = Object.keys(sortedObjByCategories);
                categories.forEach(function(category) {
                    if(sortedObjByCategories[category.id] && sortedObjByCategories[category.id].length) {
                        sortedByCategories.push(sortedObjByCategories[category.id])
                        sortedKeys.splice(sortedKeys.indexOf(category.id.toString()), 1);
                    }
                })
                if(sortedKeys && sortedKeys.length) {
                    sortedKeys.forEach(function (pseudoCategoryKey) {
                        sortedByCategories.push(sortedObjByCategories[pseudoCategoryKey])
                    })
                }
                return sortedByCategories;
            }

            function checkForCategoryLevel(item, level) {
                return item.product && item.product.family && item.product.family.categories && item.product.family.categories.length >= level;
            }

            function filterCartLineRemoved(sortedCartByCategories , line) {
                sortedCartByCategories = angular.forEach(sortedCartByCategories, function (category, key) {
                    var linesIdsToDelete = line.lines.map(function (line) {
                        return line.id;
                    });

                    var fixedCategory = _setCategoryActiveItems(category, linesIdsToDelete);
                    sortedCartByCategories[key] = fixedCategory;
                    return fixedCategory;
                });

                return sortByTree(sortedCartByCategories)
            }

            function _setCategoryActiveItems(category, linesIdsToDelete) {
                var fixedCategory = [];

                angular.forEach(category, function (item) {
                    if(!linesIdsToDelete.includes(item.id)) {
                        return fixedCategory.push(item);
                    }
                });

                return fixedCategory;
            }

            function getArrayOrder(sortedObjByCategories, categoryId) {
                var categories = (config && config.tree && config.tree.categories) ? config.tree.categories : [];
                var sortedByCategoriesKeys = []
                categories.forEach(function(category) {
                    if(sortedObjByCategories[category.id] && sortedObjByCategories[category.id].length) {
                        sortedByCategoriesKeys.push(category.id)
                    }
                })
                return sortedByCategoriesKeys[0] === categoryId ? 1 : (sortedByCategoriesKeys[sortedByCategoriesKeys.length - 1] === categoryId) || categoryId === 'unknown' ? 3 : 2;
            }

            function checkIsCaseProduct (line) {
                return line.product && line.product.branch && line.product.branch.case && line.product.branch.case.price && (line.isCase || line.product.isCaseMode);
            }

            function _setCartLinesAddedAlert(line) {
                var $oldAlertElement = angular.element(document.querySelector('.cart-line-added-alert'));
                if (!!$oldAlertElement) {
                    $oldAlertElement.remove();
                }

                var $alertElement = angular.element(document.createElement('div'))
                    .addClass('cart-line-added-alert sr-only-element');

                $alertElement[0].setAttribute('aria-live', 'assertive');
                $alertElement[0].setAttribute('role', 'alert');
                $alertElement[0].setAttribute('aria-relevant', 'additions');

                var alertText = _translateFilter('cart_line_added_alert').replace('{cart}', Config.retailer.settings.setCartNameToBasket ? Config.retailer.settings.setCartNameToBasket : 'cart') + ' ' + _translateFilter('quantity') + ' ' + _cartLineQuantityFilter(line);
                $alertElement.text(_productNameFilter(line.product, line.product.isCaseMode) + ' ' + alertText);

                document.body.appendChild($alertElement[0]);
            }

            function addDeliveryFeeLineIfNeeded() {
                // 1 delivery, 2 pickup
                var deliveryTypeId = $rootScope.config.getBranchArea() ? $rootScope.config.getBranchArea().deliveryTypeId : 1;

                if (deliveryTypeId) {
                    cart.addLine({
                        quantity: 1,
                        type: SP_SERVICES.CART_LINE_TYPES.DELIVERY,
                        product: {
                            id: -1
                        },
                        areaId: $rootScope.config.getBranchArea() && $rootScope.config.getBranchArea().id ? $rootScope.config.getBranchArea().id : 2157,
                        deliveryTypeId: deliveryTypeId,
                    });
                }
            }

            $rootScope.$on('cart.lines.add', function(event, data) {
                _showLinesBubblesOnEvent(event, data);
                angular.forEach(data.lines, function(line) {
                    _setCartLinesAddedAlert(line);
                });
            });

            $rootScope.$on('cart.lines.remove', function(event, data) {
                angular.forEach(data.lines, function (line) {
                    if(line && line.product && line.product.productId) {
                        DataLayer.push(DataLayer.EVENTS.REMOVE_FROM_CART, {products: [line.product], data: {quantity: 1}});
                    }
                });
            });

            $rootScope.$on('cart.lines.add.click', function(event, data) {
                angular.forEach(data.lines, function (line) {
                    if(line && line.product && line.product.productId) {
                        DataLayer.push(DataLayer.EVENTS.ADD_TO_CART, {products: [line.product], data: {quantity: 1}});
                    }
                });
            });

            // todo need to implement the 'replacement selected' api here as well.
            // $rootScope.$on('cart.product.replace', function(event, data) {
            //     console.log('cart.product.replace has happened data = ', data);
            //     if(data.product && data.product.productId && data.product.oosProduct && data.product.oosProduct.productId) {
            //         DataLayer.removeFromCart(data.product.oosProduct, 1);
            //         delete data.product.oosProduct;
            //         DataLayer.addToCart(data.product, 1);
            //         // DataLayer.removeFromCart(data.product.oosProduct, 1).then(function() {
            //         //     delete data.product.oosProduct;
            //         //     console.log('remove from cart finsihed, now adding');
            //         //     DataLayer.addToCart(data.product, 1);
            //         // });
            //     }
            // });

            $rootScope.$on('cart.lines.quantityChanged.click', function (event, data) {
                angular.forEach(data.lines, function (line) {
                    if (line && line.product && line.product.productId) {
                        if (data.direction === 'increase') {
                            DataLayer.push(DataLayer.EVENTS.ADD_TO_CART, {products: [line.product], data: {quantity: 1}});
                        } 
                        if (data.direction === 'decrease') {
                            DataLayer.push(DataLayer.EVENTS.REMOVE_FROM_CART, {products: [line.product], data: {quantity: 1}});
                        }
                        // manual quantity input
                        if (!data.direction) {
                            var count = Math.abs(line.oldQuantity - line.quantity);
                            var event = line.quantity > line.oldQuantity ? DataLayer.EVENTS.ADD_TO_CART : DataLayer.EVENTS.REMOVE_FROM_CART
                            DataLayer.push(event, {products: [line.product], data: {quantity: count}});
                        }
                    }
                });
            });

            $rootScope.$on('cart.crossProductTagTypes.change', _showDeliveryWithinDaysWarning);
            $rootScope.$on('cart.lines.quantityChanged.click', _showLinesBubblesOnEvent);
            $rootScope.$on('cart.lines.quantityLimit', _showQuantityLimitDialog);
            $rootScope.$on('cart.lines.deliveryItemsLimit', _handleDeliveryItemsLimit);

            $rootScope.$on('cart.update.complete', function() {
                var linesForSuggestions = [],
                    allLines = [];
                localStorageService.setItem('serverCartId', cart.serverCartId || undefined);
                cart.outOfStockLines = [];

                var count = 0;
                angular.forEach(cart.lines, function (line) {
                    count++;
                    line.isProductOutOfStock = util.isProductOutOfStock(line);
                    line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);

                    // setDebugIsForStock(line, count);

                    var isExistLine = cart.outOfStockLines.find(function(l) {
                        return l.id == line.id;
                    });

                    if ((line.isProductOutOfStock || line.isCouponActive === false) && !isExistLine) {
                        cart.outOfStockLines.push(line);
                    }

                    if (isEligibleForReplacementSuggestions(line)) {
                        linesForSuggestions.push(line);
                    }

                    allLines.push(line);
                });

                if (linesForSuggestions.length) {
                    setReplacements(linesForSuggestions, allLines);
                }

                return userService.getData().then(function(userData) {
                    if (!userData.loyaltyClubs || !userData.loyaltyClubs.length && !cart.alertRegisterIfCartAboveFlag &&
                        !!(config.retailer.loyaltyClubDrivers || []).find(function (driver) {
                            return driver.clientConfig && cart.total.simulateClubsGiftsForView && driver.clientConfig.alertRegisterIfCartAbove
                                && driver.clientConfig.alertRegisterIfCartAbove < cart.total.priceForView
                        })) {
                        cart.alertRegisterIfCartAboveFlag = true;
                        util.showLoyaltyClubDialog();
                    }
                });

            });

            function setDebugIsForStock(line, index) {
                if (index === 1 || index === 2 || index === 4 || index === 6) {
                    line.isProductOutOfStock = true;
                    line.isNeedToShowOutOfStockLabel = true;
                }

                // line.isProductOutOfStock = true;
                // line.isNeedToShowOutOfStockLabel = true;
            }

            function isEligibleForReplacementSuggestions(line) {
                if (!_isRetailerPremiumReplacementsEnabled) {
                    return false;
                }
                
                var productId = line.product && line.product.id;
                return Number(line.type) === SP_SERVICES.CART_LINE_TYPES.PRODUCT && productId && (line.isProductOutOfStock || line.isNeedToShowOutOfStockLabel);
            }

            function isSuggestionsActive(line) {
                if (!line || !line.product || !line.product._suggestions || !line.product._suggestions.length || !line.product._suggestions[0].id) {
                    return false;
                }

                return isEligibleForReplacementSuggestions(line);
            }

            function setReplacements(lines, allLines) {
                if (!allLines) {
                    allLines = _getCartLinesArray();
                } else {
                    allLines = allLines.concat(_getCartLinesArray());
                }

                var allLinesToSend = allLines.map(function(line) {
                    return line.product.id;
                });

                var uniqueLines = allLinesToSend.filter(function(productId, index) {
                    return allLinesToSend.indexOf(productId) === index; // filter first of id only to filter duplicates
                });

                var productIdMap = {};
                userService.getReplacementSuggestions(lines.map(function(line) {
                    // this is currently creating display issues, so disable for now
                    // line.product._suggestions = [{}];
                    productIdMap[line.product.id] = line;
                    return line.product.id;
                }), uniqueLines).then(function(data) {
                    angular.forEach(data, function(product) {
                       var line = productIdMap[product.id];
                       if (line && line.product && product.suggestions && product.suggestions.length) {
                           line.product._suggestions = product.suggestions;
                       }
                    });

                    _resetLines(productIdMap);
                    !$rootScope.$$phase && $rootScope.$apply();
                }).catch(function() {
                    _resetLines(productIdMap);
                    !$rootScope.$$phase && $rootScope.$apply();
                });
            }

            function _resetLines(productIdMap) {
                angular.forEach(productIdMap, function(line) {
                    if (line && line.product && line.product._suggestions) {
                        if (!line.product._suggestions.length || !line.product._suggestions[0].id) {
                            delete line.product._suggestions;
                        }
                    }
                });
            }
            
            function _getCartLinesArray() {
                var allLines = [];
                angular.forEach(cart.lines, function(line) {
                    allLines.push(line);
                });
                return allLines;
            }

            /**
             * @param {number} deliveryTimeTypeId // delivery.time.typeId
             * @param {string} fromTime // delivery.time.newFrom
             * @param {string} toTime // delivery.time.newTo
             * @param {number} daysRange
             * @return {Date}
             */
            function _calculateSaleDate(deliveryTimeTypeId, fromTime, toTime, daysRange) {
              var MINUTE_IN_MS = 1000 * 60;
              var DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

              /** @type {Date} */
              var saleDate;

              if (deliveryTimeTypeId === DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_DAYS) {
                saleDate = new Date();

                saleDate.setTime(
                  saleDate.getTime() +
                    daysRange * DAY_IN_MS -
                    saleDate.getTimezoneOffset() * MINUTE_IN_MS
                );
              } else if (fromTime && toTime) {
                var date = new Date(fromTime),
                  time = new Date(toTime);

                saleDate = new Date(
                  date.getUTCFullYear(),
                  date.getUTCMonth(),
                  date.getUTCDate(),
                  time.getUTCHours(),
                  time.getUTCMinutes(),
                  time.getUTCSeconds()
                );

                saleDate.setMinutes(saleDate.getMinutes() - saleDate.getTimezoneOffset());
              }

              return saleDate;
            }

            /**
             * @param {Object} timeTravel // Cart.timeTravel used for collection time POS
             * @param {number} giftsForView // Cart.total.giftsForView
             * @param {number} clubsGiftsForView // Cart.total.clubsGiftsForView
             * @returns {boolean}
             */
            function _checkIsSkipDialogSpecial(timeTravel, giftsForView, clubsGiftsForView){
                var skipDialogSpecial = false;

                if (!timeTravel && !giftsForView && !clubsGiftsForView) {
                  skipDialogSpecial = true;
                }

                // if the specials will be calculated by the checkout time at the pos, there is no reason to check invalid sales
                if (util.checkIsCalculateCheckoutTime()){
                  skipDialogSpecial = true;
                }

                return skipDialogSpecial
            }

            /**
             * Get irrelevant cart lines
             * @param {*[]} cartLines 
             * @param {string} fromTime
             * @param {boolean} skipDialogSpecial
             * @returns {{irrelevantSalesLines: *, lineTypes: Record<SP_SERVICES.CART_LINE_TYPES, boolean> }}
             */
            function _getIrrelevantSales(cartLines, fromTime, skipDialogSpecial) {
              var irrelevantSalesLines = [],
                lineTypes = {},
                deliveryDate = new Date(fromTime);
                deliveryDate.setUTCHours(0, 0, 0);

              angular.forEach(cartLines || [], function (line) {
                var isExpireSpecialCoupon = util.isIrrelevantSale(line, skipDialogSpecial);
                var isNotSellOnDate = !util.isSellOnDate(line, deliveryDate);

                if (isExpireSpecialCoupon || isNotSellOnDate) {
                  irrelevantSalesLines.push(line);
                  lineTypes[line.type] = true;
                }
              });

              return { irrelevantSalesLines: irrelevantSalesLines, lineTypes: lineTypes };
            }

            /**
             * @param {CartLine[]} cartLines
             * @returns {CartLine[]}
             */
            function _getOutOfStockLines(cartLines) {
                var outOfStockLines = []

                angular.forEach(cartLines, function (line) {
                line.isProductOutOfStock = util.isProductOutOfStock(line);
                line.isNeedToShowOutOfStockLabel = util.isNeedToShowOutOfStockLabel(line);

                var isExistLine = outOfStockLines.find(function (l) {
                    return l.id == line.id;
                });

                if (
                    (line.isProductOutOfStock || line.isCouponActive === false) &&
                    !isExistLine
                ) {
                    outOfStockLines.push(line);
                }
                });

                return outOfStockLines
            }

            /**
             * @typedef {Object} ExpiredInfoItem
             * @property {string} type 
             * @property {Line[] | Special[] | Coupon[]} items
             * @property {string} mainTitle
             * @property {string} subTitle
             * @property {string} itemTemplateUrl
             * @property {string=} className
             */

            /**
             * @typedef {Object} ExpiredInfo
             * @property {ExpiredInfoItem} outOfStock
             * @property {ExpiredInfoItem} sellDates
             * @property {ExpiredInfoItem} special
             * @property {ExpiredInfoItem} coupon
             */


            /**
             * @param {CartLine[]} irrelevantSalesLines
             * @param {CartLine[]} cartLines 
             * @param {string} fromTime 
             * @return {ExpiredInfo}
             */
            function _filterIrrelevantSpecialsAndCoupons(
              irrelevantSalesLines,
              cartLines,
              fromTime
            ) {
              var irrelevantSpecialIndex = {};
              var deliveryDate = new Date(fromTime);
              deliveryDate.setUTCHours(0, 0, 0);    

              /** @type {ExpiredInfo} */
              var expiredInfo = {
                outOfStock: {
                  type: "outOfStock",
                  items: [],
                  mainTitle: "",
                  subTitle: "",
                  itemTemplateUrl: "template/dialogs/sell-date/product.html",
                  className: "sell-date-dialog",
                },
                sellDates: {
                  type: "sellDates",
                  items: [],
                  mainTitle: "",
                  subTitle: "",
                  itemTemplateUrl: "template/dialogs/sell-date/product.html",
                  className: "sell-date-dialog",
                },
                special: {
                  type: "special",
                  items: [],
                  mainTitle: "",
                  subTitle: "",
                  itemTemplateUrl:
                    "template/views/cart-summary/partials/carousel-special-item/index.html",
                  className: "expired-special-wrapper",
                },
                coupon: {
                  type: "coupon",
                  items: [],
                  mainTitle: "",
                  subTitle: "",
                  itemTemplateUrl:
                    "template/views/cart-summary/partials/carousel-coupon-item/index.html",
                },
              };

              angular.forEach(irrelevantSalesLines, function (line) {
                if (line.product) {
                  // for checkout time, specials are already removed -> need to check again
                  // ECOM-10777: need check before checking specials since some special expire but they already have new specials -> check those specials will not match with original gift
                  // TODO check for coupon later. Temporary assume coupon do not expire on checkout time
                  if (util.checkIsCalculateCheckoutTime() && util.isIrrelevantSale(line, true)) {
                    expiredInfo.special.items.push(line);
                  }

                  else if (line.product.branch && line.product.branch.specials) {
                    var specials = line.product.branch.specials;

                    angular.forEach(line.gifts, function (gift) {
                      var endDate = new Date(gift.promotion.endDate);
                      var giftId = gift.promotion.id;

                      if (endDate < deliveryDate && !irrelevantSpecialIndex[giftId]) {
                        irrelevantSpecialIndex[giftId] = true;

                        if (specials) {
                          // This gift is a special, we can extract its information in the specials
                          var special = _findSepcial(specials, giftId);

                          if (!special) {
                            // In case, a product is applied both special and coupon, because the coupon does not exist in special fields, so we treat it as a coupon
                            expiredInfo.coupon.items.push(gift);
                          } else {
                            special.frontendImageUrl =
                              line.product && line.product.image && line.product.image.url;
                            expiredInfo.special.items.push(special);
                          }
                        } else if (line.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                          expiredInfo.coupon.items.push(gift);
                        }
                      }
                    });
                  }

                  if (!util.isSellOnDate(line, deliveryDate)) {
                    expiredInfo.sellDates.items.push(line);
                  }
                }
              });

              expiredInfo.outOfStock.items = _getOutOfStockLines(cartLines);

              return expiredInfo;
            }

            /**
             * @param {*[]} specials 
             * @param {number} specialId 
             * @returns {*}
             */
            function _findSepcial(specials, specialId) {
                var result = specials.find(function(special) {
                    return special.id === specialId;
                });
                return result;
            }

            /**
             * @param {ExpiredInfo} expiredInfo 
             * @param {*[]} irrelevantSalesLines
             * @returns 
             */
            function _checkShouldSkipIrrelevantSalesDialog(expiredInfo, irrelevantSalesLines, outOfStockLines) {
              var isNewPromotionDesignEnabled =
                $rootScope.config.retailer.settings.isNewPromotionDesignEnabled === "true";

              var isEmptyExpiredItem =
                !expiredInfo.coupon.items.length &&
                !expiredInfo.special.items.length &&
                !expiredInfo.sellDates.items.length;

              var isEmptyOutOfStock = expiredInfo.outOfStock.items.length === 0;

              var isEmptyIrrelevantsSales =
                (isNewPromotionDesignEnabled && isEmptyExpiredItem) || !irrelevantSalesLines.length;

              return (
                isEmptyIrrelevantsSales &&
                isEmptyOutOfStock
              );
            }

            /**
             * @typedef {Object} ExpiredSpecialLine
             * @property {number} cartLineId
             * @property {number} productId
             * @property {Special} special
             */

            /**
             * 
             * @param {ExpiredInfo} expiredInfo 
             * @param {string} orderId
             * @returns {Promise<{specials: ExpiredSpecialLine[], coupons: Coupon[]}>}
             */
            function _getMissingExpiredInfo(expiredInfo, orderId){
                return $q.all([
                    _getExpiredSpecials(expiredInfo.special, orderId),
                    _getExpiredCoupons(expiredInfo.coupon)
                ]).then(function(result){
                    return {
                        specials: result[0],
                        coupons: result[1]
                    }
                })
            }

            /**
             * @param {ExpiredInfoItem} expiredInfoSpecial 
             * @returns {Promise<Special[]>}
             */
            function _getExpiredSpecials(expiredInfoSpecial, orderId) {
              if (
                !util.checkIsCalculateCheckoutTime() ||
                !expiredInfoSpecial ||
                !expiredInfoSpecial.items ||
                !expiredInfoSpecial.items.length ||
                !orderId
              ) {
                return $q.resolve(null);
              }

              var productIds = expiredInfoSpecial.items.map(function(eis){
                return eis.product.id
              })

              if(!productIds || !productIds.length){
                return $q.resolve(null);
              }

              return Api.request({
                method: "GET",
                url: "/v2/retailers/:rid/branches/:bid/orders/" + orderId + "/specials",
                params: {
                  productIds: productIds.join(","),
                },
              });
            }

            /**
             * old promotion desgin check on lines, new promotion check on specials
             * 
             * In case, we have some expired coupons
             * 
             * @param {ExpiredInfoItem} expiredInfoCoupon
             * @return {Promise<any[] | null>}
             */
            function _getExpiredCoupons(expiredInfoCoupon) {
              if (
                !expiredInfoCoupon ||
                !expiredInfoCoupon.items ||
                !expiredInfoCoupon.items.length
              ) {
                return $q.resolve(null);
              }

              var couponIds = expiredInfoCoupon.items.map(function (c) {
                return c.promotion.id;
              });

              return _getCoupons(couponIds).then(function (couponDetails) {
                return couponDetails;
              });
            }

            /**
             * @private
             * @param {number[]} couponIds coupon id list
             * @returns {Promise<any[]>}
             */
            function _getCoupons(couponIds) {
                return Api.request({
                    method: "GET",
                    url:
                    "/v2/retailers/:rid/branches/:bid/users/:uid/coupons/" +
                    couponIds.join(","),
                    params: {
                    extended: true,
                    countonly: false,
                    }
                });
            }

            /**
             * @typedef {Object} SelectedTimeSlot
             * @property {number} id
             * @property {number} type
             * @property {number} dayInWeek
             * @property {number} day
             * @property {string} from
             * @property {string} to
             * @property {string} fromDisplay
             * @property {string} hours
             * @property {string} minutes
             * @property {string} timeRange
             * @property {string} daysRange
             * @property {boolean} isActive
             * @property {number} deliveryTimeProductId
             * @property {number} deliveryProductId
             * @property {number} deliveryTimePrice
             * @property {number} typeId
             * @property {string} startDate
             * @property {string} endDate
             * @property {Object} branchDeliveryReservation
             * @property {number} pickingBranchId
             * @property {number} branchAreaId
             * @property {number} retailerBranchId
             * @property {string} newFrom
             * @property {string} newTo
             * @property {boolean} isFull
             * @property {boolean} isInactiveRecurringSlot
             * @property {Object} deliveryProduct
             */

            /**
             * @typedef {Object} IrreSalesDataType
             * @property {Record<SP_SERVICES.CART_LINE_TYPES, boolean>} lineTypes
             * @property {ExpiredInfo} expiredInfo
             * @property {boolean} skipDialogSpecial
             * @property {any[]} outOfStockLines
             * @property {any[]} irrelevantSalesLines
             */

            /**
             * @param {SelectedTimeSlot} selectedTime 
             * @param {Object} curOrder
             * @param {string} curOrder.id
             * @param {string} curOrder.timePlaced
             * @return {Promise<IrreSalesDataType | null>} // return null if dont need to show popup
             */
            function checkIrrelevantSales(selectedTime, curOrder) {
              if (!selectedTime) {
                $q.resolve(null);
              }

              var saleDate = _calculateSaleDate(
                selectedTime.typeId,
                selectedTime.newFrom,
                selectedTime.newTo,
                selectedTime.daysRange
              );

              var skipDialogSpecial = _checkIsSkipDialogSpecial(
                cart.timeTravel,
                cart.total.giftsForView,
                cart.total.clubsGiftsForView
              );

              var saveCartPromise = $q.resolve();

              var shouldTimeTravelCollectionTime = !!(!skipDialogSpecial && saleDate);
              var shouldTimeTravelCheckoutTime = !!(
                skipDialogSpecial &&
                curOrder &&
                curOrder.timePlaced
              );

              if (shouldTimeTravelCollectionTime || shouldTimeTravelCheckoutTime) {
                cart.setTimeTravel({
                  date: skipDialogSpecial ? curOrder.timePlaced : saleDate,
                  override: false,
                });
                saveCartPromise = cart.save();
              }

              return saveCartPromise.then(function () {
                var irrelevantSalesObj = _getIrrelevantSales(
                  cart.lines,
                  selectedTime.newFrom,
                  skipDialogSpecial
                );

                var irrelevantSales = irrelevantSalesObj.irrelevantSalesLines;
                var lineTypes = irrelevantSalesObj.lineTypes;

                /** @type {ExpiredInfo} */
                var expiredInfo = _filterIrrelevantSpecialsAndCoupons(
                  irrelevantSales,
                  cart.lines,
                  selectedTime.newFrom
                );

                return _getMissingExpiredInfo(expiredInfo, curOrder.id).then(function (result) {
                  var couponDetails = result.coupons;
                  var cartLineSpecials = result.specials;

                  if (couponDetails) {
                    expiredInfo.coupon.items = couponDetails;
                  }

                  // only checkout time does not have specials since they already expired
                  if (util.checkIsCalculateCheckoutTime()) {
                    if (cartLineSpecials && cartLineSpecials.length > 0) {
                      expiredInfo.special.items = cartLineSpecials.map(function (cartLine) {
                        return cartLine.special;
                      });
                    }

                    // ECOM-10778: fix case user update once, then click update again -> special still expired but CartLineSpecial is empty -> not show empty section
                    else {
                      expiredInfo.special.items = [];
                    }
                  }                  

                  if (
                    _checkShouldSkipIrrelevantSalesDialog(
                      expiredInfo,
                      irrelevantSales,
                      cart.outOfStockLines
                    )
                  ) {
                    return $q.resolve(null);
                  }
                  

                  return $q.resolve({
                    lineTypes: lineTypes,
                    expiredInfo: expiredInfo,
                    irrelevantSalesLines: irrelevantSales,
                    skipDialogSpecial: skipDialogSpecial,
                    outOfStockLines: expiredInfo.outOfStock.items,
                  });
                });
              });
            }
            
            /**
             * @param {IrreSalesDataType} irreSalesData 
             */
            function setIrreSalesData(irreSalesData){
                cart.irreSalesData = irreSalesData;
            }

            $rootScope.$on('cart.otherCart', function(event, data) {
                Orders = Orders || $injector.get('Orders');
                if (!data.otherCart || ($state.current && $state.current.data && $state.current.data.name == 'checkout' || Orders.orderInEdit || _isInCartMerge)) {
                    return;
                }
                _isInCartMerge = true;
                util.showCommonDialog({
                    content: '<div>{{(\'you have an open cart with\' | translate).replace(\'{cart}\', $root.config.retailer.settings.setCartNameToBasket ? $root.config.retailer.settings.setCartNameToBasket : \'cart\')}} <span class="bold">' + data.otherCart.linesCount + ' {{\'products\' | translate}}</span> ' +
                    '{{\'somewhere else\' | translate}}.</div><div>{{(\'would you like to merge the two carts or continue using this cart\' | translate).replaceAll(\'{cart}\', $root.config.retailer.settings.setCartNameToBasket ? $root.config.retailer.settings.setCartNameToBasket : \'cart\')}}?</div>',
                    buttons: [
                        {
                            text: '{{(\'merge carts\' | translate).replace(\'{cart}\', $root.config.retailer.settings.setCartNameToBasket ? $root.config.retailer.settings.setCartNameToBasket : \'cart\')}}',
                            click: function () {
                                dialog.hide();
                                _isInCartMerge = false;
                                data.next(data.otherCart.id, true);
                            }
                        },
                        {
                            text: '{{(\'continue with this cart\' | translate).replace(\'{cart}\', $root.config.retailer.settings.setCartNameToBasket ? $root.config.retailer.settings.setCartNameToBasket : \'cart\')}}',
                            click: function () {
                                dialog.hide();
                                _isInCartMerge = false;
                                data.next(data.otherCart.id);
                            }
                        }
                    ],
                    disableClosing: true,
                    styleClass: 'other-cart-found'
                });
            });

            $rootScope.$on('cart.lines.inactive', function(event, data) {
                util.getActiveLines(data.lines, true);
            });

            $rootScope.$on('cart.lines.error', function(event, data) {
                util.showCommonDialog({
                    content: '{{\'The following products were not saved correctly\' | translate}}:<br/>' +
                    '<div ng-repeat="error in errors">' +
                    '{{$index + 1}}. ' +
                    '<span ng-if="!!error.line">' +
                    '{{error.line.name || error.line.product.name || ((error.line.productId || error.line.id) ? (\'With id\' | translate) + \' \' + (error.line.productId || error.line.id) : \'\')}}:' +
                    '</span>' +
                    '<span ng-if="!error.line && !!error.requestLine">' +
                    '{{error.requestLine.text || (error.requestLine.retailerProductId ? (\'With id\' | translate) + \' \' + error.requestLine.retailerProductId : \'\')}}:' +
                    '</span>' +
                    '<span>&nbsp;{{error.msg}}</span>' +
                    '</div>',
                    controller: ['$scope', function (innerScope) {
                        innerScope.errors = data.errors;
                    }]
                });
            });

            $rootScope.$on('login', function () {
                cart.save();
            });

            $rootScope.$on('logout', function () {
                cart.save({ withoutServer: true });
            });

            return cart;
        }]);

})(angular, app);
